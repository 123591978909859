<template>
  <div>
    <div class="form-row">
      <div class="col-md-12 mb-1">
        <router-link :to="{ name: 'productInboundDetail', params: { purchase_request_id: purchaseRequestId } }" class="btn btn-info" tag="a">
          <i class="fa fa-info-circle"/> Product Inbound Details
         </router-link>
        <a-button v-if="pr_items.length > 0"  title="Download" type="button" id="export-table" @click="onExcelExport" class="btn btn-success pull-right mr-2">
          <i class="fa fa-file-excel-o mr-1" aria-hidden="true"></i> Excel
        </a-button>
        <a-button v-if="pr_items.length > 0"  title="Download" type="button" @click="onPdfExport" class="btn btn-success pull-right mr-2">
          <i class="fa fa-file-pdf-o mr-1" aria-hidden="true"></i> PDF
        </a-button>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="form-row mb-4">
          <div class="col-md-6">
            <label for="pr_number">PR Number</label>
            <input class="form-control" type="text" v-model="pr_number" id="pr_number" readonly>
          </div>
          <div class="col-md-6">
            <label>Entry Date</label>
            <input class="form-control" type="text" v-model="entry_date"  id="entry_date" readonly>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-hover table-bordered text-center" id="product_inbound_list">
            <thead>
            <tr>
              <th>#</th>
              <th>Name</th>
              <th>Unit</th>
              <th>PR Quantity</th>
              <th style="color: red">Remaining Inbounded</th>
              <th>Inbounded</th>
              <th>New inbound</th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(pr_item, index) in pr_items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ pr_item.product_name }}</td>
              <td>{{ pr_item.product_unit }}</td>
              <td>{{ pr_item.pr_quantity }}</td>
              <td style="color: red">{{ remainingInbounded(pr_item.pr_quantity, pr_item.total_inbounded) }}</td>
              <td>{{ pr_item.total_inbounded }}</td>
              <td>
                <input :disabled="pr_item.pr_quantity == pr_item.total_inbounded ? true : false" class="form-control form-control-sm" :id="'qty_'+pr_item.product_id"  v-model="pr_items[index].quantity" @change="newInboundQuantity($event.target.value, index)" v-validate="{ max: 5, regex: /^[0-9]*$/ }" :name="'quantity' + index">
              </td>
              <td>
                <input :disabled="pr_item.pr_quantity == pr_item.total_inbounded ? true : false" class="form-control form-control-sm" :id="'comment_'+pr_item.id" v-model="pr_items[index].comment" :name="'comment_' + index">
              </td>
            </tr>
            </tbody>
          </table>
          <a-skeleton active :loading="loading"></a-skeleton>
        </div>
        <hr>
        <a-button v-if="pr_items.length > 0" class="btn btn-success float-left" :loading="submitLoading"  @click.prevent="save"><i class="fa fa-plus mr-1" aria-hidden="true"></i>Create Inbound</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import apiClient from '@/services/axios'
import moment from 'moment'
import $ from 'jquery'
import XLSX from 'xlsx'
// PDF
import JsPdf from 'jspdf'
import autoTable from 'jspdf-autotable'

export default {
  name: 'List',
  components: { },
  data() {
    return {
      purchaseRequestId: '',
      pr_items: {},
      product_id_array: {},
      pr_number: '',
      entry_date: '',
      loading: false,
      submitLoading: false,
    }
  },
  mounted() {
    this.purchaseRequestId = this.$route.params.purchase_request_id
    this.getPurchaseRequestDetail()
    this.entryDateFormat()
  },
  methods: {
    getPurchaseRequestDetail() {
      this.loading = true
      const purchaseRequestId = this.$route.params.purchase_request_id
      apiClient.get('api/product-inbound/create/' + purchaseRequestId)
        .then(response => {
          this.loading = false
          if (!response.data.error) {
            this.pr_items = response.data.pr_items
            this.product_id_array = response.data.product_id_array
            this.pr_number = response.data.pr_number
          } else {
            this.$notification.error({
              message: response.data.message,
            })
            this.pr_items = {}
            this.product_id_array = {}
            this.pr_number = ''
          }
        })
        .catch(error => {
          console.log(error)
        })
    },
    entryDateFormat() {
      this.entry_date = moment().format('YYYY-MM-DD')
      return moment().format('YYYY-MM-DD')
    },
    remainingInbounded(prQuantity, totalInbounded) {
      return parseFloat(prQuantity || 0) - parseFloat(totalInbounded || 0)
    },
    newInboundQuantity($event, index) {
      const quantity = $event
      const totalInbounded = this.pr_items[index].total_inbounded
      const prQuantity = this.pr_items[index].pr_quantity
      const newInboundedQuantity = parseInt(totalInbounded) + parseInt(quantity)
      if (newInboundedQuantity > prQuantity) {
        this.$notification.error({
          message: 'Total inbounded quantity is crossing PR quantity',
        })
        this.pr_items[index].quantity = ''
      }
    },
    save() {
      this.$validator.validate().then(valid => {
        if (valid) {
          const inboundArr = []
          this.product_id_array.forEach(id => {
            const qtyDiv = '#qty_' + id
            const quantity = $(qtyDiv).val()

            const commentDiv = '#comment_' + id
            const comment = $(commentDiv).val()

            if (quantity) {
              const order = {
                product_id: id,
                quantity: quantity,
                comment: comment,
              }
              inboundArr.push(order)
            }
          })

          if (inboundArr.length === 0) {
            this.$notification.error({
              message: 'Insert at least 1 item',
            })
            return
          }
          this.submitLoading = true
          const formData = new FormData()
          formData.append('product_inbound_items', JSON.stringify(inboundArr))
          formData.append('pr_number', this.pr_number)
          formData.append('entry_date', this.entry_date)
          apiClient.post('api/product-inbounds', formData, { headers: { 'Content-Type': 'multipart/form-data', 'Content-type': 'application/json' } })
            .then(response => {
              if (!response.data.error) {
                this.submitLoading = false
                this.pr_items = {}
                this.pr_number = ''
                this.$notification.success({
                  message: response.data.message,
                })
                this.getPurchaseRequestDetail()
              } else {
                this.submitLoading = false
                this.$notification.error({
                  message: response.data.message,
                })
              }
            }).catch(error => {
              this.loader = false
              this.show = true
              this.validation_errors = error.response.data.errors
              this.hide()
            })
        }
      })
    },
    onExcelExport(evt) {
      var tbl = document.getElementById('product_inbound_list')
      var wb = XLSX.utils.table_to_book(tbl)
      XLSX.writeFile(wb, 'product-inbound.xlsx')
    },
    onPdfExport() {
      const doc = new JsPdf()
      autoTable(doc, { html: '#product_inbound_list' })
      doc.save('product-inbound.pdf')
    },
  },
}
</script>

<style scoped>
.form-control-sm {
  height: calc(1.7em + .5rem + 2px) !important;
}
</style>
